import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <path
        fill="#fff"
        d="M24.553 6.282a9.126 9.126 0 01-2.614.716 4.566 4.566 0 002.001-2.519 9.102 9.102 0 01-2.89 1.105 4.553 4.553 0 00-7.757 4.152A12.925 12.925 0 013.91 4.979a4.554 4.554 0 001.409 6.078 4.538 4.538 0 01-2.062-.57v.056a4.556 4.556 0 003.652 4.466 4.578 4.578 0 01-2.058.078 4.555 4.555 0 004.255 3.162 9.137 9.137 0 01-6.74 1.886 12.878 12.878 0 006.977 2.046c8.375 0 12.954-6.937 12.954-12.954 0-.196-.005-.394-.014-.589a9.253 9.253 0 002.271-2.356z"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
